import { CutStageMappingByOrganizations } from '@/interfaces/cutStageMappingByOrganizations';
import { Feature } from '@turf/helpers';

export const getUniqCutStages = (features: Feature[], cutStageField: string): string[] => {
  if (features?.length && cutStageField) {
    const cutStageSet = new Set();
    features.forEach((feature: Feature) => {
      const value = feature.properties[cutStageField];
      if (value !== undefined && value !== null) {
        cutStageSet.add(value);
      }
    });
    return Array.from(cutStageSet) as string[];
  }
  return [];
};

export const getDefaultCutStageValue = (
  cutStage: string,
  cutStageMappingByOrganizations: CutStageMappingByOrganizations,
  orgId: string
): number => {
  return cutStageMappingByOrganizations?.[orgId]?.[cutStage] ?? parseInt(cutStage);
};
